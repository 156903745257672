<template>
  <ImagePicture
    v-if="rokka && style.type === 'picture'"
    :imageStyle="style"
    :alt="alt"
    :title="title"
    :sourceWidth="rokka.width"
    :sourceHeight="rokka.height"
    :hash="rokka.hash"
    :filename="filename"
    :host="host"
  />
  <ImageSizes
    v-else-if="rokka && style.type === 'sizes'"
    :imageStyle="style"
    :alt="alt"
    :title="title"
    :sourceWidth="rokka.width"
    :sourceHeight="rokka.height"
    :hash="rokka.hash"
    :filename="filename"
    :host="host"
  />
</template>

<script lang="ts" setup>
import type { ImageFileFragment } from '#graphql-operations'
import { imageStyles, StyleName } from '#vuepal/rokka'
import ImagePicture from './Picture/index.vue'
import ImageSizes from './Sizes/index.vue'

const props = defineProps<{
  alt?: string
  title?: string
  file?: ImageFileFragment
  styleName: StyleName
  host?: string
}>()

const filename = computed(() => {
  return props.file?.label || ''
})

const rokka = computed(() => {
  return props.file?.rokka
})

const style = computed(() => {
  // @TODO: Maybe provide a better default.
  // The default is set here so that it's type checked and we don't end up
  // using a style that doesn't exist anymore.
  const style: StyleName = props.styleName
  return imageStyles[style]
})
</script>
