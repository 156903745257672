export function buildRokkaUrl(
  stack = '',
  dpr = '',
  hash = '',
  name = '',
  host = '',
  token = ''
): string {
  const fileName = (name || '').replace('.pdf', '.jpg')
  const options = dpr ? '/options-dpr-' + dpr : ''
  const url = `https://${host}/${stack + options}/${hash}/${encodeURIComponent(
    fileName
  )}?h=${token}`

  return url
}
