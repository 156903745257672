<template>
  <picture class="block">
    <source
      v-for="(source, index) in sources"
      :key="index"
      :data-srcset="source.srcset"
      :media="source.media"
    />
    <img
      :alt="alt"
      :title="title"
      :data-src="fallback"
      :loading="loading"
      itemprop="image"
      class="block w-full"
      v-bind="imageAttributes"
    />
  </picture>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { VIEWPORTS, DPR, ImageStylePicture } from '#vuepal/rokka'

const NO_CROP_STYLES: string[] = []

const config = useRuntimeConfig()

const props = defineProps({
  imageStyle: {
    type: Object as PropType<ImageStylePicture>,
    default: null,
  },
  alt: {
    type: String,
    default: '',
  },
  sourceWidth: {
    type: Number as PropType<number | null>,
    default: 0,
  },
  sourceHeight: {
    type: Number as PropType<number | null>,
    default: 0,
  },
  title: {
    type: String,
    default: '',
  },
  hash: {
    type: String,
    default: '',
  },
  filename: {
    type: String,
    default: '',
  },
  loading: {
    type: String,
    default: '',
  },
  host: {
    type: String,
    default: '',
  },
})

const imageAttributes = computed(() => {
  if (NO_CROP_STYLES.includes(props.imageStyle.name)) {
    return {
      width: props.sourceWidth,
      height: props.sourceHeight,
    }
  }

  return undefined
})

const sources = computed(() => {
  return props.imageStyle.stacks
    .map((stack) => {
      const minWidth = VIEWPORTS[stack.viewport]
      const media = stack.viewport !== 'sm' ? `(min-width: ${minWidth}px)` : ''
      const srcset = DPR.map((v) => {
        const url = buildRokkaUrl(
          stack.name,
          v,
          props.hash,
          props.filename,
          props.host || config.public.rokkaHost,
          config.public.imageHash
        )
        const pixelRatio = v || '1'

        return `${url} ${pixelRatio}x`
      }).join(', ')

      return { srcset, media, minWidth }
    })
    .sort((a, b) => {
      return b.minWidth - a.minWidth
    })
})

const fallback = computed(() => {
  const stack = props.imageStyle.stacks[0].name
  return buildRokkaUrl(
    stack,
    '',
    props.hash,
    props.filename,
    config.public.rokkaHost,
    config.public.imageHash
  )
})
</script>
