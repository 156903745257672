
export const DPR = ['', '2']

export type StyleName = keyof typeof imageStyles

export const VIEWPORTS = {
  
} as const

export type Viewport = keyof typeof VIEWPORTS

export type PictureStack = {
  name: string
  mode?: string
  viewport: Viewport
  width: number
  height: number
  crop?: boolean
}

export type SizeStack = {
  name: string
  width: number
  height: number
  mode: string
  crop: boolean
}

export type ImageStylePicture = {
  name: string
  type: 'picture'
  stacks: PictureStack[]
  ratio?: {
    w: number
    h: number
  }
}

export type ImageStyleSizes = {
  name: string
  type: 'sizes'
  stacks: SizeStack[]
  viewport: Partial<Record<Viewport, number>>
  ratio?: {
    w: number
    h: number
  }
}

export type ImageStyle = ImageStylePicture | ImageStyleSizes

export type RokkaConfig = Record<string, ImageStyle>
export const imageStyles: RokkaConfig = {} as RokkaConfig
